export const environment = {
  production: true,
  url: '',
  url_java     : 'https://svri.api.tictacsante.com/rest',
  url_api     : 'https://api-ri.tictacsante.com/rest',
  google_apiKey: 'AIzaSyDet-CrlVTg8WWSTbDLdivRzDTBdVpeTUI',
  gtag_id: 'G-BNFT0QSD6G',
  recaptcha: {
    'siteKey': '6Ldy37IqAAAAAGOrWOC_cdQwz9auGtZl0NlrwPci',
  },
  publicCryptoKey: `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtsuvRPO3OHUnMDDgrUML
  ZlbojMq6rssedpZkgtcxUrjsXsWh+1+BT3V4ejpV8ZvqGeSKnZcH/Q8cyktHDQ7c
  F9nFW2sYheJuRD/cpyoV79eXYepVsKcxydT/IWGlod7SmpEfHY3o8NYkLAjJkj2r
  yK2bMmKuPpBEZoD6VDgudoE0jjvMmB+28kqPAyL48Yd3gPLTuRsSlCF3xd1RtG6q
  RsalRInWvkkIJO4UHjRqrdEJMwUDOLxNt0ELUZyWSSNmjHHB34a7bjpz4PPJxA+F
  RLpdVMT6AJkNbW6tb4SpQvgXyhISCHjEBRQgaNduFDgBPR4yDg7KhukdvRBc67a9
  UwIDAQAB
  -----END PUBLIC KEY-----`
};
