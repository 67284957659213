import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {EaHttpAbstract} from "../../../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})

export class ListService {
  private _url: string;
  practitiensSubject : Subject<any[]> = new Subject<any[]>();
  annuairesSubject : Subject<any[]> = new Subject<any[]>();
  markersFromSearchResultSubject : Subject<any> = new Subject<any>();
  practiciens = [];
  annuaires = [];
  markersFromSearchResult:any;
  search = new BehaviorSubject<any>(null);

  /**
   * Constructor
   *
   *
   * @param {HttpClient} _httpClient
   * @param _eaHttpAbstract
   */
  constructor(
    private _httpClient: HttpClient,
    private _eaHttpAbstract: EaHttpAbstract
  ) {
    this._url = environment.url_api;
  }

  getAllData(Data: any):Promise<any>{
    return new Promise((resolve, reject) => {
      Promise.all([
        this.searchSp(Data).subscribe(response=> {
          this.search.next(response.data);
        })
      ]).then(([]) => {
          resolve([]);
      }, reject);
    });
  }
  /**
   * Add Call
   *
   * @param data
   * @returns {Promise<any>}
   */
  getTimesSlote(data: any): Promise<any> {
    /**
     * Params
     */
    let httpParams = new HttpParams();
    httpParams = httpParams.append('reference_directory', data.reference);
    httpParams = httpParams.append('reference', data.reference);
    httpParams = httpParams.append('displays', data.display_days);
    httpParams = httpParams.append('date_init', data.date_init);
    httpParams = httpParams.append('type', '');
    httpParams = httpParams.append('default', '');

    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + '/planning/time_slots', httpParams, {
          // headers: headers,
          // params: httpParams,
          responseType: 'json',
          withCredentials: true,
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            //  console.log(response);
            resolve(response);
          },
          (err) => {
            console.log('Error: ' + err.error);
            console.log('Name: ' + err.name);
            console.log('Message: ' + err.message);
            console.log('Status: ' + err.status);
          },
          reject
        );
    });
  }

  /////////////////////////

  /**
   * Retreive Person Data
   * * @param keyword
   * *@param data: Array
   * * @returns {Observable<any>}
   */
  searchSp(data: any): Observable<any> {
    return this._eaHttpAbstract.post("/public/search", data)
        .pipe(
            map((response)=>{
              return response;
            })
        );
  }

  getPopularSpecialities(): Observable<any> {
    return this._eaHttpAbstract.get("/public/specialties/popular")
        .pipe(
            map((response)=>{
              return response;
            })
        );
  }
  /**
   * get Call Histories
   *
   * @param data: Array
   * @returns {Observable<any>}
   */
  searchResults(data: any): Observable<any> {
    /**
     * Params
     */
    return this._eaHttpAbstract.post("/public/practices/search_results", data)
        .pipe(
            map((response: any)=>{
              this.practiciens = response.data.practices;
              this.emitPracticiens();
              this.annuaires = response.data.directories;
              this.emitAnnuaires();
              this.markersFromSearchResult = response.data.result_map;
              this.emitMarkersFromSearchResultSubject();
              return response;
            })
        );
  }

  emitPracticiens(){
    this.practitiensSubject.next(this.practiciens);
  }
  emitAnnuaires(){
    this.annuairesSubject.next(this.annuaires);
  }
  emitMarkersFromSearchResultSubject(){
    this.markersFromSearchResultSubject.next(this.markersFromSearchResult);
  }
}
