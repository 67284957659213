import { Injectable } from '@angular/core';
import * as Forge from 'node-forge';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RSAHelper {
    private publicKey: Forge.pki.rsa.PublicKey;
    
    constructor() {
        this.publicKey = Forge.pki.publicKeyFromPem(environment.publicCryptoKey);
    }
    encryptWithPublicKey(valueToEncrypt: string): string {
        return window.btoa(this.publicKey.encrypt(valueToEncrypt.toString()));
    }
}